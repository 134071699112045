import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Error404 from "./Pages/404";

import Home from "./Pages/Home";
import Registration from "./Pages/Registration";
import ThankYou from "./Pages/ThankYou";

export default function AppRouter() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home} />
                <Route path="/registration" exact component={Registration} />
                <Route path="/thankyou" exact component={ThankYou} />
                <Route path="/*" exact component={Error404} />
            </Switch>
        </BrowserRouter>
    );
}
