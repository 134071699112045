import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
    footer: {
        textAlign: "center",
        paddingTop: 60,
        color: "white",
    },
    link: {
        color: "white",
    },
}));

export default function Footer() {
    const classes = useStyles();
    const [date] = useState(new Date());
    return (
        <footer className={classes.footer}>
            <span>
                Copyright © {date.getFullYear()}{" "}
                <a href="https://www.stoegercanada.ca/" className={classes.link}>
                    Stoeger Canada
                </a>
            </span>
        </footer>
    );
}
