import React, { useState } from "react";
import {
    Button,
    Card,
    Checkbox,
    Divider,
    FormControl,
    Grid,
    List,
    ListItem,
    makeStyles,
    MenuItem,
    TextField,
    Typography,
    useTheme,
} from "@material-ui/core";
import Header from "../component/Header";
import Footer from "../component/Footer";
import clsx from "clsx";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
    background: {
        backgroundImage: "url(/images/Navigator_00031.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundColor: theme.palette.grey[50],
        backgroundSize: "cover",
        backgroundPosition: "center",
    },
    card: {
        width: "80%",
        background: "rgba(255, 255, 255, 0.85)",
        margin: theme.spacing(8, "auto", 2),
        padding: theme.spacing(4, 0, 6),
        [theme.breakpoints.only("sm")]: {
            width: "95%",
        },
        [theme.breakpoints.only("xs")]: {
            width: "100%",
        },
    },
    text: {
        textAlign: "center",
        margin: "auto",
    },
    text2: {
        textAlign: "center",
    },
    divider: {
        background: "rgba(0, 0, 0, 0.87)",
        width: "25%",
        margin: theme.spacing(3, "auto"),
    },
    submit: {
        "&:hover, &.Mui-focusVisible": { backgroundColor: "white", opacity: 0.5 },
        "&:hover": {
            "& .MuiSvgIcon-root": {
                color: "black",
            },
        },
    },
    email: {
        textAlign: "center",
        margin: "0 auto",
        width: "85%",
    },
    input: {
        "& label.Mui-focused": {
            color: "black",
        },
        "& label": {
            color: "black",
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: "black",
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: "black",
            },
            "&:hover fieldset": {
                borderColor: "#263238",
            },
            "&.Mui-focused fieldset": {
                borderColor: "black",
            },
        },
    },
    button_div: {
        margin: theme.spacing(4, "auto", 0),
    },
    button: {
        margin: theme.spacing(2, "auto", 0),
    },
    button2: {
        margin: theme.spacing(2, "auto", 2),
    },
    form: {
        marginTop: theme.spacing(2),
    },
    newsletterForm: {
        padding: theme.spacing(0, 3),
    },
    checkboxs: {
        margin: theme.spacing(2, 0),
    },
    checkbox: {
        margin: theme.spacing(0, 1),
        padding: 0,
    },
    subscribeBtn: {
        margin: theme.spacing(1, "auto", 0),
    },
}));

export default function ThankYou() {
    const classes = useStyles();
    const theme = useTheme();
    const [newsletterData, setNewsletterData] = useState({
        first: "",
        last: "",
        email: "",
        province: "",
        subscribe: false,
    });
    const [subscribed, setSubscribed] = useState(false);

    const handleSubscribe = (e) => {
        e.preventDefault();

        const tmp = steralizeInput(newsletterData);

        fetch("https://steiner-promo.herokuapp.com/api/subscribeToStoeger", {
            // fetch("http://localhost:8080/api/subscribeToStoeger", {
            headers: {
                "Content-Type": "application/json",
            },
            method: "post",
            body: JSON.stringify(tmp),
        })
            .then((res) => {
                console.log(res);
                if (res.status === 200) {
                    setSubscribed(true);
                    // reset form for another user to subscribe
                    setNewsletterData({
                        first: "",
                        last: "",
                        email: "",
                        province: "",
                        subscribe: false,
                    });
                }
            })
            .catch((err) => console.log(err));
    };

    const steralizeInput = (form) => {
        let tmp = Object.entries(form);
        tmp = tmp.map((m) => (typeof m[1] === "string" ? [m[0], m[1].replace(/^\s+|\s+$/gm, "")] : m));

        return Object.fromEntries(tmp);
    };

    const handleFormData = (e) => {
        setNewsletterData({
            ...newsletterData,
            [e.target.name]: e.target.value,
        });
    };
    const handleChecks = (e) => {
        setNewsletterData({
            ...newsletterData,
            [e.target.name]: e.target.checked,
        });
    };

    return (
        <div>
            <Header />
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                className={classes.background}
                style={{ minHeight: "100vh" }}
            >
                <Card className={classes.card}>
                    <List>
                        <ListItem>
                            <div className={classes.text}>
                                <Typography variant="h3">THANK YOU FOR ENTERING!</Typography>
                            </div>
                        </ListItem>
                    </List>
                    <Divider classes={{ root: classes.divider }} />
                    <List>
                        <ListItem>
                            <div className={classes.text}>
                                <Typography variant="h3">MERCI D'ENTREZ!</Typography>
                            </div>
                        </ListItem>
                    </List>
                    <div align="center" className={classes.button_div}>
                        <Button
                            className={classes.button}
                            color="secondary"
                            variant="contained"
                            component="a"
                            href="https://www.stoegercanada.ca/brands/sako/"
                        >
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <div>Register for lifetime warranty with steiner</div>
                                <div>Inscrivez-vous pour la garantie à vie avec steiner</div>
                            </div>
                        </Button>
                        <div align="center" className={classes.button}>
                            <Button
                                className={classes.button2}
                                component="a"
                                href="https://www.stoegercanada.ca/brands/steiner/"
                                style={{ textDecoration: "underline" }}
                            >
                                Return to Steiner Canada
                            </Button>
                        </div>
                    </div>

                    <Divider classes={{ root: classes.divider }} />
                    <form onSubmit={handleSubscribe} className={classes.newsletterForm}>
                        <div className={classes.text}>
                            <Typography variant="h5" gutterBottom>
                                Sign up for our newsletter
                            </Typography>
                            <Typography variant="h5" gutterBottom>
                                Inscrivez-vous à notre bulletin
                            </Typography>
                        </div>
                        <Typography align="center" variant="body1">
                            Make sure to join our Newsletter for online sales, contests, draws and new product
                            information.
                        </Typography>
                        <Typography align="center" variant="body1">
                            Assurez-vous de vous inscrire à notre bulletin pour de l'information sur nos ventes en
                            ligne, concours, tirages et nouveaux produits.
                        </Typography>
                        <div className={classes.form}>
                            <Grid container spacing={3}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        className={clsx(classes.spacing, classes.input)}
                                        label="First Name / Prénom"
                                        fullWidth
                                        required={true}
                                        name="first"
                                        value={newsletterData.first}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        className={clsx(classes.spacing, classes.input)}
                                        label="Last Name / Nom de famille"
                                        fullWidth
                                        required={true}
                                        name="last"
                                        value={newsletterData.last}
                                        onChange={handleFormData}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        className={clsx(classes.spacing, classes.input)}
                                        required={true}
                                        fullWidth
                                        name="email"
                                        value={newsletterData.email}
                                        onChange={handleFormData}
                                        variant="outlined"
                                        label="Email / Courriel"
                                        type="email"
                                    />
                                </Grid>
                                <Grid item md={6} sm={12} xs={12}>
                                    <TextField
                                        variant="outlined"
                                        className={clsx(classes.spacing, classes.input)}
                                        fullWidth
                                        label="Province / Province"
                                        name="province"
                                        value={newsletterData.province}
                                        required={true}
                                        onChange={handleFormData}
                                        select
                                    >
                                        <MenuItem value="AB">Alberta</MenuItem>
                                        <MenuItem value="BC">British Columbia</MenuItem>
                                        <MenuItem value="MB">Manitoba</MenuItem>
                                        <MenuItem value="NB">New Brunswick</MenuItem>
                                        <MenuItem value="NL">Newfoundland and Labrador</MenuItem>
                                        <MenuItem value="NT">Northwest Territories</MenuItem>
                                        <MenuItem value="NS">Nova Scotia</MenuItem>
                                        <MenuItem value="NU">Nunavut</MenuItem>
                                        <MenuItem value="ON">Ontario</MenuItem>
                                        <MenuItem value="PE">Prince Edward Island</MenuItem>
                                        <MenuItem value="QC">Quebec</MenuItem>
                                        <MenuItem value="SK">Saskatchewan</MenuItem>
                                        <MenuItem value="YT">Yukon</MenuItem>
                                    </TextField>
                                </Grid>
                            </Grid>
                            <div className={classes.checkboxs}>
                                <FormControl>
                                    <Typography variant="body2" style={{ display: "inline" }}>
                                        <Checkbox
                                            required
                                            checked={newsletterData.subscribe}
                                            size="small"
                                            onChange={handleChecks}
                                            name="subscribe"
                                            color="secondary"
                                            style={{ display: "inline-flex", padding: "0", marginRight: "8px" }}
                                        />
                                        *{" "}
                                        <strong>
                                            Please subscribe me to Stoeger Canada, the Canadian distributor for Steiner
                                            and Steiner Ltd.'s Newsletter.
                                        </strong>{" "}
                                        By checking this box, I opt-in to receive periodic information on promotions,
                                        sales, events and new products as well as the chance to participate in any
                                        future draws by Stoeger Canada and Steiner International. |{" "}
                                        <strong>
                                            S'il vous plaît inscrivez-moi au bulletin de Stoeger Canada, le distributeur
                                            canadien de Steiner et Steiner Ltd.{" "}
                                        </strong>{" "}
                                        En cochant cette case, j'accepte de recevoir des informations périodiques sur
                                        les promotions, les ventes, les événements et les nouveaux produits, ainsi que
                                        la possibilité de participer aux tirages ultérieurs de Stoeger Canada et Steiner
                                        Internationale.
                                    </Typography>
                                </FormControl>
                            </div>
                        </div>
                        {subscribed && (
                            <Alert severity="success" style={{ margin: theme.spacing(2, 0) }}>
                                Sign up successful! Sign up another email. | Inscription réussie! Inscrivez un autre
                                courriel.
                            </Alert>
                        )}
                        <div align="center">
                            <Button
                                className={classes.subscribeBtn}
                                color="secondary"
                                variant="contained"
                                type="submit"
                            >
                                Subscribe / Inscrire
                            </Button>
                        </div>
                    </form>
                </Card>
                <Footer />
            </Grid>
        </div>
    );
}
